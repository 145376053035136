import React from 'react';
import './Contain.css';
import '../../Media.css';
import Portfolio from '../Main/Portfolio';
import About from '../Main/About';
import Skills from '../Main/Skills';
import Footer from '../Footer/Footer'
import Blog from '../Main/Blog';
import { Route, Switch } from 'react-router-dom';
import SingleNew from '../Mini_comp/NewSingle';




function Contain() {
  
   
    return (
        <div className="Contain">
            <Switch>
            <Route exact path='/'><About /> </Route>
            <Route path='/skills'><Skills /> </Route>
            <Route path='/portfolio'><Portfolio /> </Route>
            <Route exact path='/blog'> <Blog /> </Route>
            <Route path={`/blog/:contain`} component={SingleNew}/> 
            </Switch>
            <Footer />
        </div>
    )
}

export default Contain;

