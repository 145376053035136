import React from 'react'
import './Footer.css'
import Contact from '../Contact/Contact'

function Footer() {
    return(
        <div className="footer">
            <Contact/>
        </div>
    )

}
export default Footer