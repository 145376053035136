import React from 'react';
import BackButton from './backButton/BackButton';
import { data } from '../../data/data'
import './NewSingle.css'
import "../../MultipleUse.css"


function SingleNew(props) {
    const newdata = [...data]
    const newId = props.location.pathname.split("/")[2]
    const contentData = newdata.filter((item) => {
        return (
            item.id === Number(newId)
        )
    })
    console.log(contentData)
    console.log(props)
    const newcontentData = contentData[0]
    const { heading, photo, text } = newcontentData
    return (
        <div className="container_single text_style">
            <div className="clear">
                <div classname="head_single">
                    <div className="heading_single">
                        {heading}
                    </div>
                    <div className="body_single">
                        <img className="photo_single" src={photo} alt="" />
                        <p className="text_single text_style">
                            {text}
                        </p>
                    </div>
                </div>
                <div className="back_button_single">
                    <BackButton />
                </div>
            </div>
        </div>
    )
}
export default SingleNew