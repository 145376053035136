import React, { useState } from 'react'
import github from '../../Image/icons/github.png'
import linkedin from '../../Image/icons/linkedin.png'
import facebook from '../../Image/icons/facebook.png'
import yahoo from '../../Image/icons/yahoo.png'


import './Contact.css';
import '../../Media.css';


function Contact() {
    const [state] = useState([
        {id:1, link: 'https://www.linkedin.com/in/seda-adams/', bgimage: linkedin, name: "linkendin" },
        {id:2,  link: 'https://github.com/sedasha', bgimage: github, name: "github" },
        {id:3,  link: 'https://www.facebook.com/seda.shahgyaldyan/', bgimage: facebook, name: "fb" },
        {id:4, link: 'https://www.linkedin.com/in/seda-adams/',bgimage: yahoo, name: "yahoo"}
    ])
    return (
            <div className="container_contact">
                <p className="GetTouch">Get in touch with me at</p>
                <div className="Icons">
                    {state.map(e =>
                        <a href={e.link} target='blank' key={e.id} >
                            <div  className="icon">
                                 <img src={e.bgimage} alt={e.name} width="25px" height="25px"/>
                            </div>
                        </a>)}
                </div>
            </div>
    )
}

export default Contact
