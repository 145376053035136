import React from 'react'
import {Button} from 'reactstrap'
import jsPDF from 'jspdf'
import page1 from '../../Image/0001.jpg'
import page2 from '../../Image/0002.jpg'
import page3 from '../../Image/0003.jpg'
import  './DownloadCv.css';



function Downloadcv (){
   const PdfGenerate =()=>{
        var doc = new jsPDF('p', 'mm', 'a4','false');
        
        doc.addImage(page1, 'JPG', 0, 0, 200, 300)
        doc.addPage()
        doc.addImage(page2, 'JPG', 0, 0, 200, 300)
        doc.addPage()
        doc.addImage(page3, 'JPG', 0, 0, 200, 300)

        doc.save('Seda_Adams_cv.pdf')
    }
    return(
        <div>
            <Button className="download_button" onClick={PdfGenerate}>Download my CV</Button>
        </div>
    )
}
export default Downloadcv