import React from 'react';
import './BlogComponent.css';
import { useHistory } from 'react-router-dom';
import "../../MultipleUse.css"



function BlogComponent({ heading, text, photo, id, photo_jpg, ...props }) {

    const history = useHistory()
    const changeLink = () => {
        history.push(`/blog/${id}`)
    }

    return (
        <div className='MiniBlog' onClick={changeLink}>
            <picture> 
                <sourse type="image/webp" srcSet={photo}></sourse>
                <sourse type="image/jpg" srcSet={photo_jpg}></sourse>
                <img src={photo} className="image" alt="" />
            </picture>
                <h2> {heading}</h2>
                <div className='text'>
                    <p className='wide text_style'>
                        {text}
                    </p>
                </div>
                <div className='date'></div>
        </div>

    )
}

export default BlogComponent


