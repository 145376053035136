import React from 'react';
import BlogComponent from '../Mini_comp/BlogComponent';
import "./Blog.css"
import "../../MultipleUse.css"
import { data } from '../../data/data'



function Blog(props) {


    const newdata = [...data]
    console.log(newdata)
    return (

        <div className='container_blog container'>
            <div>
                <h1>
                    My blog
                </h1>
            </div>
            <div className="Blog ">
                {newdata.map((e, ind) => {
                    return (
                        <BlogComponent key={ind} heading={e.heading} text={e.text} photo={e.photo} photo_jpg={e.photo_jpg} id={e.id} />)
                })}

            </div>


        </div>

    )
}

export default Blog

