import React from 'react';
import "./Frame.css";


function Frame({ frametitle, link, frameimage, width, height, frameImage_png }) {
    return (
        <div className="frame">

            <h2>{frametitle}</h2>
            <a href={link} target="blank" >
                    <picture>
                        {/* <sourse type="image/webp" srcSet={frameimage} width={width} height={height}></sourse>
                        <sourse type="image/jpg" srcSet={frameImage_png} width={width} height={height}></sourse> */}
                        <img src={frameimage} alt={frametitle} width={width} height={height} />
                    </picture>
            </a>

        </div>
    )
}

export default Frame