import React from 'react'
import "./Portfolio.css"
import Frame from '../Mini_comp/Frame';
import Matson from "../../Image/images/portfolio/2.webp";
import Matson_png from "../../Image/images/portfolio/2.png";
import Calculator from "../../Image/images/portfolio/3.webp";
import Calculator_png from "../../Image/images/portfolio/3.png";
import n6 from "../../Image/images/portfolio/6.webp";
import n6_png from "../../Image/images/portfolio/6.png";
import shop from "../../Image/images/portfolio/shop.webp";
import shop_png from "../../Image/images/portfolio/shop.png";
import tictac from "../../Image/images/portfolio/tictac.webp";
import tictac_png from "../../Image/images/portfolio/tictac.png";
import random from "../../Image/images/portfolio/random.webp";
import random_png from "../../Image/images/portfolio/random.png";
import naysis from "../../Image/images/portfolio/naysis.png"
import tflogistics from "../../Image/images/portfolio/tflogistics.png"
import animals from "../../Image/images/portfolio/animals.webp";
import animals_png from "../../Image/images/portfolio/animals.png";
import CSSstyle from "../../Image/images/portfolio/CSS_style.webp";
import CSSstyle_png from "../../Image/images/portfolio/CSS_style.png";
import "../../MultipleUse.css"


let arrFrame = [
    {
        id: 1,
        frametitle: "TF logistics LLC",
        frameimage: tflogistics,
        frameImage_png: tflogistics,
        width: "200px",
        height: "200px",
        link: 'https://tflogisticsllc.org/',
    },
    {
        id: 2,
        frametitle: "Naysis.com",
        frameimage: naysis,
        frameImage_png: naysis,
        width: "150px",
        height: "200px",
        link: 'https://naysis.com/',
    },
    {
        id: 3,
        frametitle: "Matson Adams musician",
        frameimage: Matson,
        frameImage_png: Matson_png,
        width: "200px",
        height: "200px",
        link: 'https://sedasha.github.io/Matsonadamsmusic.github.io/',
    },
    {
        id: 4,
        frametitle: "Investment Calculator",
        frameimage: Calculator,
        frameimage_png: Calculator_png,
        width: "150px",
        height: "180px",
        link: 'https://sedasha.github.io/dividend-calculator.githup.io/#pop',

    },
    {
        id: 5,
        frametitle: "Online shop",
        width: "150px",
        height: '150px',
        frameimage: shop,
        frameimage_png: shop_png,
        link: 'https://sedasha.github.io/onlineshop/',
    },
    {
        id: 6,
        frametitle: "Tic tac toe",
        width: "150px",
        height: '200px',
        frameimage: tictac,
        frameimage_png: tictac_png,
        link: 'https://sedasha.github.io/tic_tac_toe/',
    },
    {
        id: 7,
        frametitle: "Random number",
        width: "150px",
        height: '200px',
        frameimage: random,
        frameimage_png: random_png,

        link: 'https://sedasha.github.io/RandomNumber/',
    },
    {
        id: 8,
        frametitle: "Animals",
        width: "150px",
        height: '200px',
        frameimage: animals,
        frameimage_png: animals_png,

        link: 'https://sedasha.github.io/animals.github.io/',
    },
    {
        id: 9,
        frametitle: "Get CSS style",
        width: "150px",
        height: '200px',
        frameimage: CSSstyle,
        frameimage_png: CSSstyle_png,

        link: 'https://sedasha.github.io/css_transition_tool.github.io/',
    },
    {
        id: 10,
        frametitle: "",
        width: "150px",
        height: '200px',
        frameimage: n6,
        frameimage_png: n6_png,

        link: '',
    }

]


function Portfolio() {
    return (
        <div className="portfolio container">
            <h1>Projects</h1>
            <div className="container_portfolio"> 
                {arrFrame.map(element => {
                    return (
                        <Frame 
                        frametitle={element.frametitle} 
                        frameimage={element.frameimage} 
                        frameImage_png={element.frameImage_png} 
                        width={element.width} 
                        height={element.height} 
                        link={element.link} 
                        key={element.id} />
                    )
                })}

            </div>

        </div>
    )
}

export default Portfolio


