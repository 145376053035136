import React from 'react';
import './Skills.css';
import "../../MultipleUse.css"
import Skill from '../Mini_comp/Skill'

function Resume() {

    const data = [
        {
            id: 1,
            program: "HTML5/CSS3"
        },
        {
            id: 2,
            program: "SCSS/SASS"
        },
        {
            id: 3,
            program: "Bootstrap"
        },
        {
            id: 4,
            program: "JavaScript"
        },
        {
            id: 5,
            program: "React"
        },
        {
            id: 6,
            program: "Redux"
        },
        {
            id: 7,
            program: "Git, terminal, npm"
        },
        {
            id: 8, 
            program: "Github"
        },
        {
            id: 9,
            program: "Jira"
        },
        {
            id: 10,
            program: "Figma"
        }
    ]
    return (
        <div className="Skills container">
            <h1>Skills</h1>
            <div className="container_small">
                {
                    data.map(elem => {
                        return (
                            <Skill key={elem.id} program={elem.program} />
                        )
                    })
                }

            </div>
        </div>
    )
}

export default Resume

