import './BackButton.css'
import React from 'react'
import {useHistory} from 'react-router-dom'

function BackButton() {
    const history = useHistory()
    const backPage = ()=>{
        history.goBack()
    }
    console.log(history)
    return (
        <div>
            <button className="back_button" onClick={backPage}>back</button>
        </div>
    )
}

export default BackButton
