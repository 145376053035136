import React, { Component } from 'react';
import './App.css';
import './Media.css';
import Contain from './components/Contain/Contain';
import Header from './components/Header/Header';




class App extends Component {
  render() {
    return (
      <div className="App">
        <Header />
        <Contain />
      </div>
      
    );
  }
}

export default App;

