import React  from 'react';
import './Skill.css'

function Skill ({program, logo}){
    return (
        <div className="container_skill_single">
            <h2>{program}</h2>
        </div>
    )

}
export default Skill