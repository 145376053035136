import React from 'react';
import "./About.css"
import "../../MultipleUse.css"



function About() {
    return (
 
            <div className="about container">
                    <div className="main">
                            <h1>&nbsp;&nbsp; Hello, <br />&nbsp;&nbsp;  I’m Seda, front end developer.</h1>
                            <p> &nbsp;&nbsp;&nbsp; I found myself interested in web and mobile programming, as I like finding new solutions for the problems and there is always something I can learn and grow. 
                            <br /> &nbsp;&nbsp;&nbsp; I am well-organized, punctual, problem solver with high attention on details. I like to code things from the scratch and making ideas real. I am a person who enjoys working with others also. Interested in the enire front end spectrum and working on ambition project and being a part of a group.
                            <br /> &nbsp;&nbsp;&nbsp; Family person, who enjoys listening my husband Matson’s guitar playing at evenings or spending time with my cats. During my free time you can find me reading an inspiration book, walking in a park, making traditional armenian food, dancing or meditating.</p>
                    </div>
            </div>
    
    )
}

export default About
